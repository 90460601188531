<template>
    <div class="dark:bg-gray-900 flex">
        <div class="container mx-auto">
            <div class="max-w-md p-5 mx-auto my-10 bg-white rounded-md shadow-sm">
                <div class="text-center">
                    <h1 class="dark:text-gray-200 my-3 text-3xl font-semibold text-gray-700">Contact Us</h1>
                    <p class="dark:text-gray-400 text-gray-400">Fill up the form below to send us a message.</p>
                </div>
                <div class="m-7">
                    <form action="https://api.web3forms.com/submit" method="POST" id="form">
                        <input type="hidden" name="apikey" value="YOUR_ACCESS_KEY_HERE" />
                        <input type="hidden" name="subject" value="New Submission from Web3Forms" />
                        <input type="checkbox" name="botcheck" id="" style="display: none" />

                        <div class="mb-6">
                            <label for="name" class="dark:text-gray-400 block mb-2 text-sm text-gray-600"
                                >Full Name</label
                            >
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="John Doe"
                                required
                                class="focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div class="mb-6">
                            <label for="email" class="dark:text-gray-400 block mb-2 text-sm text-gray-600"
                                >Email Address</label
                            >
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="you@company.com"
                                required
                                class="focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div class="mb-6">
                            <label for="message" class="dark:text-gray-400 block mb-2 text-sm text-gray-600"
                                >Your Message</label
                            >

                            <textarea
                                rows="5"
                                name="message"
                                id="message"
                                placeholder="Your Message"
                                class="focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500 w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md"
                                required
                            ></textarea>
                        </div>
                        <div class="mb-6">
                            <button
                                type="submit"
                                class="focus:bg-indigo-600 focus:outline-none w-full px-3 py-4 text-white bg-indigo-500 rounded-md"
                            >
                                Send Message
                            </button>
                        </div>
                        <p class="text-base text-center text-gray-400" id="result"></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
};
</script>

<style scoped></style>
